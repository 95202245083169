import Vue from 'vue'
import shabdaApi from '../api/shabda-api.js'

const CACHE_KEY_CONTRIBUTION_FORM = 'shabda_form_contribution_data'

const state = Vue.observable({
  loadingLetters: false,
  loadingNotices: false,
  loadingEulogies: false,
  letters: [],
  notices: [],
  eulogies: [],
  editingForm: null,
  selectedContribution: null
})

const defaultContributionFormData = () => {
  return {
    id: '',
    label: '',
    contribution_date: new Date().toLocaleDateString('en-gb'),
    location: '',
    eulogy_for: '',
    title: '',
    author: '',
    content: '',
    contribution_type: '',
    status: 'created',
    step: 1
  }
}

export const contributionForm = Vue.observable(defaultContributionFormData())
const saving = Vue.observable({
  isSaving: false
})

export default {
  state: state,
  contributionForm: contributionForm,
  isSaving: saving,
  load() {
    if (localStorage.getItem(CACHE_KEY_CONTRIBUTION_FORM)) {
      const formData = JSON.parse(localStorage.getItem(CACHE_KEY_CONTRIBUTION_FORM))
      for (const prop in formData) {
        contributionForm[prop] = formData[prop]
      }
    }
  },
  persist() {
    if (!saving.isSaving) {
      setTimeout(() => {
        saving.isSaving = false
      }, 500)
    }

    saving.isSaving = true
    localStorage.setItem(CACHE_KEY_CONTRIBUTION_FORM, JSON.stringify(contributionForm))
  },
  clear() {
    const defaults = defaultContributionFormData()
    for (const prop in defaults) {
      contributionForm[prop] = defaults[prop]
    }
    localStorage.removeItem(CACHE_KEY_CONTRIBUTION_FORM)
  },
  storedData() {
    if (localStorage.getItem(CACHE_KEY_CONTRIBUTION_FORM)) {
      return JSON.parse(localStorage.getItem(CACHE_KEY_CONTRIBUTION_FORM))
    }

    return defaultContributionFormData()
  },
  fetchContributions() {
    this.fetchLetters()
    this.fetchNotices()
    this.fetchEulogies()
  },
  fetchLetters() {
    state.loadingLetters = true
    shabdaApi.fetchAll('letters')
      .then(data => {
        state.letters = data.letters
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        state.loadingLetters = false
      })
  },
  fetchNotices() {
    state.loadingNotices = true
    shabdaApi.fetchAll('notices')
      .then(data => {
        state.notices = data.notices
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        state.loadingNotices = false
      })
  },
  fetchEulogies() {
    state.loadingEulogies = true
    shabdaApi.fetchAll('eulogies')
      .then(data => {
        state.eulogies = data.eulogies
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        state.loadingEulogies = false
      })
  },
  loadFormData(contribution) {
    contributionForm.id = contribution.contributionItem.id
    contributionForm.label = contribution.contributionItem.label
    contributionForm.content = contribution.contributionItem.content
    contributionForm.contribution_type = contribution.contributionItem.type
    contributionForm.contribution_date = (contribution.contributionItem.date) ? new Date(contribution.contributionItem.date).toLocaleDateString('en-gb') : new Date().toLocaleDateString('en-gb')
    contributionForm.location = contribution.contributionItem.location || ''
    contributionForm.eulogy_for = contribution.contributionItem.eulogy_for || ''
    contributionForm.title = contribution.contributionItem.title || ''
    contributionForm.author = contribution.contributionItem.author || ''
  },
  editContribution(contribution) {
    state.editingForm = contribution.contributionItem.id
    state.selectedContribution = contribution
  },
  clearEditContribution(contribution) {
    state.editingForm = null
    state.selectedContribution = null
  }
}
