<template>
  <button class="c-comment-entry-box" @click="$emit('enter-comment')" type="button">
    <div class ="c-comment-entry-box__text">{{ msg }}</div>
  </button>
</template>

<script>
export default {
  props: {
    commentingEnabled: {
      type: Boolean,
      default: true
    },
    commentCount: {
      type: [Number, String]
    }
  },
  computed: {
    msg() {
      if (this.commentingEnabled) {
        return 'take part in this conversation...'
      } else if (this.commentCount > 0) {
        return 'comments for this post are now closed'
      }
      return 'comments for this post are turned off'
    }
  }
}
</script>

<style lang="scss">
@import "src/www/sites/all/themes/tbc/scss/_variables.scss";
@import "@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss";

.c-comment-entry-box{
  border: 1px solid rgba(151,151,151, 1);
  background-color: white;
  opacity: 1;
  width: 100%;
  height: 90px;
  @media screen and (width <= $mobile-max-size) {
    height: 40px;
  }
}

.c-comment-entry-box__text{
  color: rgba(151,151,151, 1);
  font-family: $font-family-open-sans;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.63px;
  text-align: left;
  margin-left: 16px;
  @media screen and (width <= $mobile-max-size) {
    font-size: 16px;
    letter-spacing: 0.5px;
  }
}

</style>
