<template>
  <div class="flex">
    <ValidationObserver ref="form">
    <form ref="contributeForm" @submit.prevent="onSubmit" method="POST" class="contribute-form">
      <input type="hidden" name="id" v-model="form_id" />
      <input type="hidden" name="contribution_type" v-model="form_contribution_type" />
      <input type="hidden" name="content" v-model="formData.content" />

      <ValidationProvider name="title" rules="required|max:255" v-slot="{ errors }">
      <div class="form_input">
        <label>Title</label>
        <input type="text" name="title" v-model="formData.title" placeholder="Enter title" @input="persist"/>
        <span>{{ errors[0] }}</span>
      </div>
      </ValidationProvider>

      <ValidationProvider name="content" rules="required" v-slot="{ errors }">
      <div class="form_input">
        <label class="sr-only">Content</label>
        <Editor v-model="formData.content" @input="persist" />
        <span>{{ errors[0] }}</span>
      </div>
      </ValidationProvider>

      <p>You have submitted {{ wordCount(formData.content).words }} words</p>

      <p>
      <button type="submit" class="btn btn-success btn-action">preview and submit</button>
      </p>
    </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Editor from '../Editor.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelopeOpen, faExclamation, faCommentAlt } from '@fortawesome/free-solid-svg-icons'
import store from '../../store/shabda'
import { wordCount } from '../../utils/string-utils'

library.add(faEnvelopeOpen, faExclamation, faCommentAlt)

export default {
  components: {
    Editor,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    id: String,
    author: String,
    title: String,
    label: String,
    content: String,
    showAdminOptions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form_id: this.id,
      form_title: this.title,
      form_label: this.label,
      form_contribution_type: 'notice'
    }
  },
  computed: {
    formData() {
      return store.contributionForm
    }
  },
  methods: {
    persist() {
      store.persist()
    },
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }

        this.$emit('send')

        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    wordCount(htmlString) {
      return wordCount(htmlString)
    }
  }
}
</script>
