<template>
  <a v-if="!loggedIn" :href="loginUrl" class="c-comment-entry-bar__sign-in-section">
    <div class="c-comment-entry-bar__divider-line"></div>
    <div class="c-comment-entry-bar__view-guidelines c-comment-entry-bar__sign-in">sign in to view responses</div>
  </a>
  <div v-else class="c-comment-entry-bar">
    <div class="c-comment-entry-bar__divider-line"></div>
    <div class="c-comment-entry-bar__chat-bar">
      <template v-if="displayComments">
      <font-awesome-icon :icon="['fal', 'comments']" />
      <div class="c-comment-entry-bar__comment-count">{{ commentCount }}</div>
      <div class="c-comment-entry-bar__spacer-line comments">|</div>
      </template>
      <a :href="flagForModerationUrl" alt="Flag this post to be moderated by the team."><font-awesome-icon class="c-comment-entry-bar__exclamation-circle" :icon="['fal', 'exclamation-circle'] " /></a>
    </div>
    <CommentEntryBox
      @enter-comment="enterComment(id)"
      :commentingEnabled="commentingEnabled"
      :commentCount="commentCount"
    />
    <div class="c-comment-entry-bar__bottom">
      <CommentShowHideButton
        v-if="commentCount > 0"
        :isOpen="showComments"
        @toggle-comments="toggleComments"
      />
      <a v-if="commentingEnabled" class="c-comment-entry-bar__view-guidelines" @click="showModal = true">view our guidelines for promoting good conversation</a>
    </div>
    <div v-if="showComments">
      <div id="comments" class="responses-section">
        <div class="c-comment-entry-bar__divider-line responses"></div>
        <div v-if="showLabel" class="responses-label-container">
          <div class="responses-label-text">
            Parts of this conversation fall short of meeting our <span class="label-guidelines" @click="showModal = true">community guidelines</span> around positive, empathetic and harmonious speech, and ways of relating to others online. We’ve decided to keep the conversation here because it also contains some helpful discussion and exemplification of Buddhist practice in this regard.<br><br>The Triratna Buddhist Order and Community is committed to fostering diversity as a practical aspiration in our presentation of the Dharma online, and at our Buddhist Centres around the world.
          </div>
        </div>
        <div class="responses-flex">
          <div class="responses-text">responses</div>
          <div class="responses-right-flex">
            <a :class="{ 'responses-order': true, 'old': true, 'active': isOrderOldFirst }" href="?corder=ofirst#comments">order by oldest first</a>
            <div class="c-comment-entry-bar__spacer-line">|</div>
            <a :class="{ 'responses-order': true, 'new': true, 'active': isOrderNewFirst }" href="?corder=nfirst#comments">order by newest first</a>
          </div>
        </div>
        <div class="c-comment-entry-bar__divider-line responses"></div>
      </div>
      <div v-html="modifiedComments"></div>
    </div>

    <FullscreenModal v-if="showModal" @close="showModal = false">
      <div slot="header" class="modal-header">
        <a class="close-button" @click="showModal = false">
        <i class="clickable fas fa-times"></i>
      </a>
      </div>
      <CommunityGuidelines slot="body"/>
      <div slot="footer"/>
    </FullscreenModal>

  </div>
</template>

<script>
import utils from '../api/utils.js'
import FullscreenModal from '@dharmachakra/tbco-shared-styles/src/components/FullscreenModal.vue'
import CommunityGuidelines from './CommunityGuidelines.vue'
import CommentEntryBox from './CommentEntryBox.vue'
import CommentShowHideButton from './CommentShowHideButton.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faComments, faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
library.add(faComments, faExclamationCircle)

export default {
  props: {
    commentCount: {
      type: [Number, String]
    },
    comments: {
      type: String,
      default: ''
    },
    id: {
      type: Number
    },
    loggedIn: {
      type: Boolean,
      default: true
    },
    commentingEnabled: {
      type: Boolean,
      default: true
    },
    displayComments: {
      type: Boolean,
      default: true
    },
    flagForModerationUrl: {
      type: String,
      default: ''
    },
    commentsOpen: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CommentEntryBox,
    CommentShowHideButton,
    FullscreenModal,
    CommunityGuidelines
  },
  data() {
    return {
      showComments: this.commentsOpen,
      showModal: false,
      hover: false,
      modifiedComments: ''
    }
  },
  computed: {
    loginUrl() {
      return '/login?destination=' + encodeURIComponent(window.location.href)
    },
    isOrderOldFirst() {
      return window.location.search.includes('corder=ofirst') || !window.location.search.includes('corder=nfirst')
    },
    isOrderNewFirst() {
      return window.location.search.includes('corder=nfirst')
    }
  },
  mounted() {
    this.scrollToCommentsIfHash()
    this.updateAllExternalLinks()
  },
  methods: {
    updateAllExternalLinks() {
      this.modifiedComments = utils.updateAllExternalLinksInString(this.comments)
    },
    scrollToCommentsIfHash() {
      const hash = window.location.hash
      if (hash === '#comments') {
        this.showComments = true
        const element = document.querySelector(hash)
        if (element) {
          element.scrollIntoView({ behavior: 'auto' })
        }
      }
    },
    toggleComments() {
      this.showComments = !this.showComments
    },
    enterComment(id) {
      if (!this.commentingEnabled) {
        return
      }

      // open comment reply form
      let url = window.location.protocol + '//' + window.location.host
      url += `/comment/reply/${id}`
      window.location.href = url
    }
  }
}

</script>

<style lang="scss">
@import "src/www/sites/all/themes/tbc/scss/_variables.scss";
@import "@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss";

.responses-section {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  margin-bottom: -20px;
}

.responses-text {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.59px;
  text-align: left;
  @media screen and (max-width: $mobile-max-size) {
    font-size: 16px;
  }
}

.responses-order {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.59px;
  text-align: left;
  @media screen and (max-width: $mobile-max-size) {
    font-size: 16px;
  }
}

.responses-order.active {
  color: $md_lt_grey !important;
}

.responses-order.old {
  margin-right: 22px;
    @media screen and (max-width: $tablet-max-size) {
      margin-right: 17px;
    }
    @media screen and (max-width: $mobile-max-size) {
      margin-right: 3px;
    }
}

.responses-order.new {
  margin-left: 22px;
    @media screen and (max-width: $tablet-max-size) {
      margin-left: 17px;
    }
    @media screen and (max-width: $mobile-max-size) {
      margin-left: 3px;
    }
}

.responses-flex {
  display:flex;
  margin-top: 35px;
  margin-bottom: 35px;
  justify-content: space-between;
  flex-direction: row;
    @media screen and (max-width: $mobile-max-size) {
      flex-direction: column;
    }
}

.responses-right-flex {
  display:flex;
}

.responses-label-container {
  width: 100%;
  border: 1px solid rgba(151, 151, 151, 1);
  opacity: 1;
  background-color: rgba(120, 120, 120, 0.1);
  margin-top: 40px;
}

.responses-label-text {
  font-family: $font-family-open-sans;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.59px;
  line-height: 30px;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 20px;
  margin-bottom: 22px;

  @media screen and (width <= $mobile-max-size) {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 16px;
    letter-spacing: 0.47px;
  }
}

.c-comment-entry-bar {
  max-width: 720px;
}

.comment-wrapper {
  font-family: $font-family-open-sans;

  .comment-inner {
    @media screen and (width > $mobile-max-size) {
      display: flex;
    }
    margin-top: 40px;
  }

  .title {
      display: none;
  }

  .comments-avatar {
    position: relative;
    width: 90px;
    height: 90px;
    margin-right: 20px;
    @media screen and (width <= 1960px) {
      margin-right: 20px;
    }
    @media screen and (width <= $mobile-max-size) {
      width: 60px;
      height: 60px;
    }
    img {
      height: auto;
      min-width: 60px;
      @media screen and (width > $mobile-max-size) {
        min-width: 90px;
      }
    }
  }

  .links {
    display: flex;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.59px;
    gap: 28px;
    margin-top: 37px;
    align-self: flex-end;
    @media screen and (max-width: $layout-width-page) {
      gap: 17px;
    }
    @media screen and (max-width: $mobile-max-size) {
      font-size: 16px;
      letter-spacing: 0.47px;
      gap: 9px;
      margin-top: 18px;
    }
  }

  .links a, .links a:visited {
    color: $md_lt_grey;
  }

  .flag-abuse_whitelist_comment.last, .new {
      display: none;
  }

  .links li {
    display: flex;
  }

  // override the flex display for links li to hide this link
  .comment-delete-perm {
    display: none !important;
  }

  // show pipe after all but the last link
  .links ul li:not(:nth-last-child(2))::after {
    content: " | ";
    margin-left: 28px;
    color: $md_lt_grey;
    @media screen and (max-width: $layout-width-page) {
      margin-left: 17px;
    }
    @media screen and (max-width: $mobile-max-size) {
      margin-left: 9px;
    }
  }

  .submitted {
    position: relative;
    font-size: 20px;
    letter-spacing: 0.59px;
    @media screen and (width > $mobile-max-size) {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (width <= $mobile-max-size) {
      font-size: 16px;
      letter-spacing: 0.47px;
    }
  }

  .content {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.59px;
    line-height: 30px;
    margin-top: 23px;
    @media screen and (width <= $mobile-max-size) {
      font-size: 16px;
      letter-spacing: 0.69px;
    }
  }

  .comment-edit-link {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.59px;
    margin-left: 5px;
    @media screen and (width <= $mobile-max-size) {
      font-size: 16px;
      letter-spacing: 0.47px;
    }
  }

  span.submitted, span.comment-edit-link {
    display: inline-block;
  }

  .indented {
    margin-left: 131px;
    @media screen and (width <= $mobile-max-size) {
      margin-left: 30px;
    }
  }
}

.c-comment-entry-bar__divider-line{
  height: 1px;
  background-color: rgba(151,151,151, 1);
  width: 100%;
  max-width: 720px;
}

.c-comment-entry-bar__chat-bar{
  display: flex;
  margin-top: 41px;
  margin-bottom: 41px;
  font-size: 25px;
  float: right;
  align-items: center;
  @media screen and (width <= $mobile-max-size) {
    margin-bottom: 20px;
    margin-top: 21px;
    font-size: 20px;
  }
}

.c-comment-entry-bar__comment-count{
  font-family:  $font-family-open-sans;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.65px;
  margin-left: 8px;
  align-self: flex-end;
  @media screen and (width <= $mobile-max-size) {
    letter-spacing: 0.47px;
    font-size: 16px;
    padding-bottom: 4px;
  }
}

.c-comment-entry-bar__spacer-line {
  color: rgba(148,148,148,1);
  font-family: $font-family-open-sans;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.59px;
  @media screen and (max-width: $mobile-max-size) {
    font-size: 16px;
  }
}

.c-comment-entry-bar__spacer-line.comments {
  margin-left: 17px;
  margin-bottom: 2px;
  @media screen and (width <= $mobile-max-size) {
    margin-left: 11px;
  }
}

.c-comment-entry-bar__exclamation-circle{
  margin-left: 17px;
  font-size: 21px;
  @media screen and (width <= $mobile-max-size) {
    font-size: 20px;
    margin-left: 11px;
  }
}

.c-comment-entry-bar__bottom {
  display: flex;
  margin-top: 40px;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  @media screen and (width < 684px) {
    flex-wrap: wrap;
  }
  @media screen and (width <= $mobile-max-size) {
    margin-top: 20px;
  }
}

.c-comment-entry-bar__view-guidelines {
  cursor: pointer;
  color: $md_lt_grey !important;
  font-family:  $font-family-open-sans;
  text-decoration: underline;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.55px;

  @media screen and (width < 684px) {
    margin-left: 0px;
    margin-top: 20px;
  }

  @media screen and (width <= $mobile-max-size) {
    font-size: 16px;
    letter-spacing: 0.47px;
    margin-top: 14px;
  }

}

.c-comment-entry-bar__sign-in{
  margin-top: 41px;
}

.c-comment-entry-bar__sign-in-section{
  max-width: 720px;
}

.field-item th {
  text-align: left;
}

.field-items {
  margin-left: 5px;
  .file-icon {
    display: inline;
  }
  .odd {
    background-color: #f1f1f1;
  }
  .even {
    background-color: #ffffff;
  }
  .sticky-enabled {
    margin-top: 5px;
    width: 100%;
  }
}

.field-items td {
  min-width: 80px;
}

.field-items img {
  margin-right: 10px;
  margin-left: 5px;
}

.field-items a {
  word-break: break-all;
  margin-right: 5px;
}

.field-items td:first-child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.comment {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

.comment::before,
.comment::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(151, 151, 151, 1);
}

.comment::before {
  top: 19px;
}

.comment::after {
  bottom: 66px;
}

.indented .comment {
  position: relative;
}

.indented .comment::before,
.indented .comment::after {
  content: "";
  position: absolute;
  left: 0;
  width: 720px;
  height: 1px;
  background: rgba(151, 151, 151, 1);
}

.indented .comment::before {
  top: 19px;
  left: -131px;
}

.indented .comment::after {
  bottom: 66px;
  left: -131px;
}

</style>
