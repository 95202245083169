<template>
  <div>
    <div class="single-post-title-section">
      <div class="post-author-picture">
        <img v-if="author.picture" :src="author.picture" />
        <img v-else src="/sites/all/themes/tbc/images/defaults/user.png" alt="User Image">
      </div>
      <PostTitleSection :title="title"
        :subtitle="subtitle"
        :author="author.name"
        :date="created"
        :titleurl="path"
        :canEdit="canEdit"
        :editLink="'/node/' + nid + '/edit'"
        :isOm="omOnly"
      />
    </div>
    <div class="single-post">
      <div class="single-post-aside">
        <div v-if="themeLinks.length > 0">
          <div class="t-project single-post-related-links">
            <RelatedThemes :themeLinks="themeLinks" />
          </div>
        </div>
      </div>

      <div class="single-post-main">
        <div class="media-gallery">
          <div v-if="media.length > 1">
          <SingleSlider
            :stories="sliderMedia"
            slideTypeClass="slider-type__post"
            @body-clicked="openModal"
            title=""
          >
          </SingleSlider>
        </div>
          <template v-if="media.length === 1">
          <div v-for="(m, index) in media" :key="index" class="responsive responsive-16by9">
            <div v-if="m.mimeType === 'text/html'" v-html="m.metadata.html" class="media-embed" :class="{ 'soundcloud-feature-wrapper': m.provider === 'soundcloud' }"></div>
            <img v-if="isImageMime(m.mimeType)" :src="m.source" />
          </div>
          </template>
        </div>
        <FullscreenModal v-if="isModalOpen" @close="closeModal">
        <div slot="header"></div>
          <div slot="body">
            <div v-html="story.fullScreenBody"></div>
          </div>
          <div slot="footer">
            <button class="btn btn-default btn-close" @click="closeModal">x</button>
          </div>
        </FullscreenModal>

        <div v-if="quotes.length > 0" class="t-project">
          <div v-for="(quote, index) in quotes" :key="index" class="component__markup">
            <PostQuoteSection>
              <template v-slot:body>
                <main v-html="quote.content" class="post-content"></main>
              </template>
              <template v-slot:author>
                <cite>{{ quote.author }}</cite>
              </template>
            </PostQuoteSection>
          </div>
        </div>

        <div class="t-project">
          <div class="component__markup">
            <PostBodySection>
            <main v-html="content" class="post-content"></main>
            </PostBodySection>
          </div>
        </div>

        <div v-if="resources.length > 0" class="resources">
          <Resources
          :resources="resources"
          />
        </div>

        <div class="theme-links-mobile">
          <div v-if="themeLinks.length > 0">
            <div class="t-project single-post-related-links">
              <RelatedThemes :themeLinks="themeLinks" />
            </div>
          </div>
        </div>
        <div v-if="$attrs.nodeType !== 'keystone'" class="t-project">
          <div class="component__markup">
            <CommentEntryBar
              :commentCount=commentCount
              :comments=commentsHTML
              :id=nid
              :commentingEnabled="commentLevel === 2"
              :displayComments="commentLevel !== 0 && !(commentLevel === 1 && commentCount === 0)"
              :flagForModerationUrl="'/flag/confirm/flag/abuse_node/' + nid + '?destination=node/' + nid"
              :commentsOpen="commentsOpen"
              :loggedIn="isLoggedIn"
              :showLabel="showLabel"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostTitleSection from './PostTitleSection.vue'
import RelatedThemes from '@dharmachakra/tbco-shared-styles/src/components/RelatedThemes'
import PostBodySection from '@dharmachakra/tbco-shared-styles/src/components/PostBodySection'
import CommentEntryBar from './CommentEntryBar.vue'
import PostQuoteSection from '@dharmachakra/tbco-shared-styles/src/components/PostQuoteSection'
import SingleSlider from '@dharmachakra/tbco-slider/src/components/SingleSlider'
import FullscreenModal from '@dharmachakra/tbco-shared-styles/src/components/FullscreenModal.vue'
import Resources from './Resources.vue'

/**
 * @todo - Facebook video required the sdk, which requires jQuery, so leaving for now.
 * sites/all/modules/contrib/video_embed_facebook_tbc/js/video_embed_facebook_sdk.js
 */
export default {
  components: {
    PostTitleSection,
    RelatedThemes,
    PostBodySection,
    CommentEntryBar,
    PostQuoteSection,
    FullscreenModal,
    SingleSlider,
    Resources
  },
  props: {
    nid: [String, Number],
    author: Object,
    created: String,
    title: String,
    subtitle: String,
    path: String,
    type: String,
    content: String,
    heroImage: String,
    media: {
      type: Array,
      default: () => {
        return []
      }
    },
    resources: {
      type: Array,
      default: () => {
        return []
      }
    },
    tags: {
      type: Array,
      default: () => {
        return []
      }
    },
    quotes: {
      type: Array,
      default: () => {
        return []
      }
    },
    channel: Object,
    showChannelAdminMenu: {
      type: Boolean,
      default: false
    },
    loadISSUU: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    commentsHTML: {
      type: String,
      default: ''
    },
    commentLevel: {
      type: Number,
      default: 0
    },
    commentCount: {
      type: Number,
      default: 0
    },
    story: {
      type: Object,
      default: null
    },
    isModalOpen: {
      type: Boolean,
      default: false
    },
    omOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      commentsOpen: false,
      userView: null
    }
  },
  created() {
    if (window && window.location.hash.startsWith('#comment-')) {
      this.commentsOpen = true
    }
    this.userView = document.__TBCO__.userView
  },
  mounted() {
    if (this.loadISSUU || this.media.some((m) => m.provider === 'issuu')) {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', '//e.issuu.com/embed.js')
      document.head.appendChild(recaptchaScript)
    }
  },
  computed: {
    isLoggedIn() {
      if (Object.keys(this.userView).length === 0) {
        return false
      }
      return this.userView && this.userView.isLoggedIn
    },
    canEdit() {
      return (document.__TBCO__.page && document.__TBCO__.page.data.canEdit === true)
    },
    themeLinks() {
      return this.tags.map((tag) => {
        return {
          label: tag.name.toLowerCase(),
          href: '/tags/' + tag.name.toLowerCase().replaceAll(' ', '-')
        }
      })
    },
    sliderMedia() {
      return this.media.map((slide) => {
        slide.body = '<div class="responsive responsive-16by9"><img src="' + slide.source + '"></div>'
        slide.fullScreenBody = '<img src="' + slide.fullScreenSource + '">'
        return slide
      })
    }
  },
  methods: {
    openModal(story) {
      this.isModalOpen = true
      this.story = story
    },
    closeModal() {
      this.isModalOpen = false
    },
    isImageMime(mimeType) {
      const imageMimeTypes = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif'
      ]

      return imageMimeTypes.indexOf(mimeType) !== -1
    }
  }
}
</script>

<style lang="scss">
.single-post {
  display: flex;
  gap: 2rem;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 26px;
  letter-spacing: 0.77px;

  a {
    color: #000;
    text-decoration-thickness: 1px;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    color: #000;
    text-decoration-thickness: 1px;
  }

  a:visited {
    color: #000;
    text-decoration-thickness: 1px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 1rem;
  }
}

.hooper-slider-section.single-slider {
  overflow: hidden;
  padding-bottom: 0;

  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }
}
</style>

<style scoped lang="scss">
.resources {
  margin-bottom: 2rem;
}

.single-post {
  margin-bottom: 2rem;
}

.post-content {
  margin-bottom: 47px;
}

.single-post-aside {
  flex: 0 0 23%;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.theme-links-mobile {
  @media screen and (min-width: 769px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.single-post-main {
  flex: 1 1 100%;
  flex-basis: 77%;
  max-width: calc(77% - 2rem);

  @media screen and (max-width: 768px) {
    max-width: calc(100%);
  }
}

.single-post-title-section {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    padding: 0 1rem;
  }
}

.post-author-picture {
  flex: 0 0 23%;
}

.media-gallery {
  margin-bottom: 2rem;
  max-width: 720px;
}

.single-post-related-links {
  padding: 2rem 0;
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
}

.media-embed {
  position: absolute;
  height: 100%;
  width: 100% !important;

  ::v-deep {
    .iframe-feature-wrapper {
      width: 100% !important;
    }
  }
}
</style>
